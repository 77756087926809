import React from "react";
import Layout from "../components/layout";
import { Avatar, Carousel, Tabs } from "antd";
import Seo from "../components/seo";
import Help from "../components/help";
import { REQUESTS } from "../components/api";

import database from "../images/database.png";
import eclipse1 from "../images/eclipse1.png";
import eclipse2 from "../images/eclipse2.png";
import github from "../images/github.png";
import architecture from "../images/architecture.png";
import docker from "../images/docker.png";
import protocol from "../images/protocol.png";
import rmi from "../images/rmi.png";
import jndi from "../images/jndi.png";
import cluster from "../images/cluster.png";
import console from "../images/console.png";
import netbeans1 from "../images/netbeans_project.png";

const { TabPane } = Tabs;

const Home = () => (
            <Layout>
                <div className="border"><div></div></div>
                <div className="panel">
                    <Seo title="Accueil" />
                    <div className="title">ESIPE - Module JEE - TP EJB</div>
                    <h1 id="I"><span>Objectif</span></h1>
                    <p>Java Enterprise Edition, ou Java EE, est une spécification pour la technique Java d&#39;Oracle plus particuli&egrave;rement destinée aux applications d'entreprise. Ces applications sont considérées dans une approche multi-niveaux. Dans ce but, toute implémentation de cette spécification contient un ensemble d'extensions au framework Java standard (JSE, Java Standard Edition) afin de faciliter notamment la création d'applications réparties.</p>
            
                    <p>Les EJB (Entreprise Java Bean) sont un des éléments tr&egrave;s importants de la plateforme Java EE pour le développement d&#39;applications distribuées. La plateforme Java EE propose de mettre en &oelig;uvre les couches métiers et persistance avec les EJB. Une des principales caractéristiques des EJB est de permettre aux développeurs de se concentrer sur les traitements orientés métiers car les EJB et l&#39;environnement dans lesquels ils s&#39;exécutent prennent en charge un certain nombre de traitements tels que la gestion des transactions, la persistance des données, la sécurité, ...</p>
            
                    <p>L'objectif principal des TPs d'EJB est donc de construire un projet mettant en &oelig;uvre chacune des notions évoquées précédemment et ainsi comprendre le fonctionnement, l'interaction et le développement de chaque composant constituant une application JEE.</p>
            
                    <p>Pour faire le lien avec les TPs dédiés à la partie Web Profile vu avec Mr Piedeloup, le sujet du projet d'EJB sera le m&ecirc;me, à savoir, construire une application bancaire. Les EJB étant dédiés à la partie métier d'une application, l'idée est donc de transformer vos &laquo; Beans &raquo; métiers de votre projet Web en un projet EJB.</p>
            
                    <p>La partie suivante présente le cahier des charges de l'application tel qu'un client pourrait vous le formuler avec son lot de contraintes qu'il faudra impérativement respecter pour que l'application fonctionne dans son syst&egrave;me d'information.</p>
            
                    <h1 id="II"><span>Cahier des charges</span></h1>
                    <h2 id="II-I"><span>I. Contexte</span></h2>
                    <p>Un de vos clients a fait appel à deux prestataires pour opérer la transformation digitale de sa banque et ainsi proposer une plateforme de gestion enti&egrave;rement numérique. L'autre prestataire est en charge de la partie front tandis que vous opérez sur la partie back destinée à la fois aux collaborateurs des différentes agences ainsi qu'aux clients pour la gestion de leurs comptes bancaires. Il a été convenu que les deux plateformes communiqueront par Webservice Rest Json et vous &ecirc;tes donc en charge de la mise à disposition des différentes API à l'autre prestataire.</p>
                    <h2 id="II-II"><span>II. Fonctionnalités</span></h2>
                    <h3><span>Consultation</span></h3>
                    <p>L'espace de consultation sera utilisé par les clients et les collaborateurs de la banque pour consulter l'ensemble des comptes bancaires d'un client. Une premi&egrave;re page devra donc afficher l'ensemble des comptes avec les noms et soldes actuels de chaque compte. En tant que conseiller vous avez accès à une page supplémentaire (Comptes externes) qui vous liste l'ensemble des comptes qui vous sont rattachés. L'affichage des comptes se matérialisera donc par un tableau contenant :</p>
            
                    <ul>
                        <li>Le numéro du compte</li>
                        <li>Le type de compte (ch&egrave;que, épargne)</li>
                        <li>Le nom du titulaire (s'il diffère de la personne connectée)</li>
                        <li>Le solde du compte</li>
                    </ul>
            
                    <p>Un compte ch&egrave;que est un compte o&ugrave; le taux d'intér&ecirc;t est à 0 tandis qu'un compte épargne poss&egrave;de un taux &gt; 0.</p>
            
                    <p>La sélection d'un compte permet d'afficher par la suite le détail des transactions réalisées sur ce dernier ainsi que le montant des intér&ecirc;ts cumulés de l'année courante. Un tableau récapitulant les transactions sera construit sous cette forme :</p>
            
                    <ul>
                        <li>Numéro de la transaction</li>
                        <li>La date</li>
                        <li>Compte source et destination (avec le nom da personne s'il diffère de la personne connectée)</li>
                        <li>Le montant</li>
                        <li>Auteur de l'opération</li>
                        <li>Le libellé de l'opération</li>            
                    </ul>
                    <p>Chaque client est rattaché à un conseiller, les comptes sont par conséquent suivis par un seul conseiller. Chaque conseiller de l'entreprise sera donc en mesure d'accéder à l'ensemble des comptes de ses clients (via la page <a href="/externals">Comptes externes</a>). Un client quant à lui ne pourra voir que ses propres comptes.</p>
            
                    <h3><span>Transactions</span></h3>
                    <p>Un client sera en mesure de réaliser des transactions entre ses propres comptes tandis que le conseiller pourra réaliser des transactions entre des comptes de plusieurs clients qui lui sont rattachés.</p>
                    <p>Chaque transaction devra &ecirc;tre autorisée en mentionnant le compte source et destinataire avec le montant ainsi que la personne ayant réalisé l'action. Un commentaire optionnel pourra &ecirc;tre saisi par l'auteur. <span className="important">Un client ne peut pas effectuer un virement supérieur à 1 000€ sans autorisation de son conseiller.</span> Le virement est donc mis en <span className="important">attente de validation</span> jusqu'à ce que le conseiller du client valide ou non ce dernier. Le client quant à lui voit sa transaction dans l'historique avec le statut en attente de validation.</p>
                    <p>Chaque compte dispose (en fonction de son type) d'un seuil de découvert. Il est donc primordial que les fonds restants après application de la transaction sur chaque compte (émetteur et récepteur) respectent le montant suffisant pour &ecirc;tre appliqué. Dans le cas contraire, la transaction est annulée.</p>
                    <p>Chaque transaction finalisée devra &ecirc;tre historisée dans une table prévue à cet effet. Cette historisation sera visible dans l'espace de consultation du compte. Elle sera par ailleurs utilisée pour calculer les taux d'intér&ecirc;ts de chaque compte. Ayant le solde actuel, les transactions avec les dates, vous &ecirc;tes en mesure de conna&icirc;tre le solde d'un compte à un instant T.</p>
            
                    <h3><span>Taux</span></h3>
                    <p>Lors de la consultation d'un compte, il sera possible d'accéder au calcul des intér&ecirc;ts. Le calcul des intér&ecirc;ts seront calculés sur la base du solde à cet instant et par rapport au taux du compte. Il n'est pas utile de sauvegarder les taux à chaque date puisque vous &ecirc;tes en mesure de les recalculer gr&acirc;ce à l'historique des transactions.</p>
                    <p>Une fois par an, au 1er Janvier de chaque année, les taux seront appliqués à chaque compte. Cette action sera représentée sous la forme d'une transaction provenant d'un compte spécifique d' &laquo; id=1 &raquo; correspondant au compte de la banque. Il vous suffira de parcourir tous les comptes épargne et de récupérer les intér&ecirc;ts de l'année précédente puis de réaliser un virement entre le compte spécifique de la banque et celui du compte en cours de traitement.</p>
            
                    <h2 id="II-III"><span>III. Matrice des r&ocirc;les</span></h2>
                    <p>Au vu des fonctionnalités et des types de personnes utilisant l'application, le client a recensé deux types de profils :</p>
            
                    <ul>
                        <li><b>Clients :</b>
                            <ul>
                                <li>Jean Dupont (administré par Lucas Perrin)</li>
                                <li>Sophie Petit (administré par Lucas Perrin)</li>
                                <li>Christophe Moreau (administré par Vincent Fournier)</li>
                            </ul>
                        </li>
                        <li><b>Conseillers :</b>
                            <ul>
                                <li>Lucas Perrin</li>
                                <li>Vincent Fournier</li>
                            </ul>
                        </li>
                    </ul>
            
                    <p>Chaque profil sera en mesure ou non de réaliser les actions suivantes :</p>
            
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><b>Client</b></td>
                                <td><b>Conseiller</b></td>
                            </tr>
                            <tr>
                                <td><b>Consulter ses comptes</b></td>
                                <td><Avatar icon="check" style={{backgroundColor: '#87d068'}} /></td>
                        <td><Avatar icon="close" style={{backgroundColor: '#cc0000'}} /></td>
                        </tr>
                        <tr>
                            <td><b>Avoir des comptes rattachés</b></td>
                            <td><Avatar icon="close" style={{backgroundColor: '#cc0000'}} /></td>
                        <td><Avatar icon="check" style={{backgroundColor: '#87d068'}} /></td>
                        </tr>
                        <tr>
                            <td><b>Réaliser un virement (&lt;= 1 000€)</b></td>
                            <td><Avatar icon="check" style={{backgroundColor: '#87d068'}} /></td>
                        <td><Avatar icon="check" style={{backgroundColor: '#87d068'}} /></td>
                        </tr>
                        <tr>
                            <td><b>Réaliser un virement (&gt; 1 000€)</b></td>
                            <td><Avatar icon="close" style={{backgroundColor: '#cc0000'}} /></td>
                        <td><Avatar icon="check" style={{backgroundColor: '#87d068'}} /></td>
                        </tr>
                        <tr>
                            <td><b>Valider un virement</b></td>
                            <td><Avatar icon="close" style={{backgroundColor: '#cc0000'}} /></td>
                        <td><Avatar icon="check" style={{backgroundColor: '#87d068'}} /></td>
                        </tr>
                        </tbody>
                    </table>
            
                    <p>Pour des raisons de simplicité, la sécurisation par authentification n'est pas demandée, néanmoins chaque Web service transmet l'ID de la personne réalisant l'action et il sera donc à votre charge dans l'application d'autoriser une action en fonction des droits que poss&egrave;de un profil. Attention à bien faire correspondre les contraintes de votre de vue et celle de vos EJBs.</p>
            
                    <h2 id="II-IV"><span>IV. Base de données</span></h2>
                    <p>Vous allez devoir réaliser votre projet avec la base de données déjà existante du client dont voici le schéma :</p>
            
                    <p style={{textAlign: "center"}}><img alt="" width="944" src={database} /></p>
            
                    <p>La table &laquo; <b>user </b>&raquo; représente les informations d'un utilisateur, elles sont créées lors de l'ajout d'un utilisateur et seule l'adresse &laquo; email &raquo; peut &ecirc;tre actualisée.</p>
            
                    <p>Deux tables &laquo; <b>customer</b> &raquo; et &laquo; <b>advisor</b> &raquo; permettent de dissocier nos utilisateurs en étant respectivement &laquo; client &raquo; et &laquo; conseiller &raquo; et permet en outre de rattacher un client à son conseiller.</p>
            
                    <p>La table &laquo; <b>account_type</b> &raquo; est une table référen&ccedil;ant l'ensemble des comptes proposés par la banque (une sorte de catalogue). Cette table référencera le taux du compte et les capacités de découvert.</p>
            
                    <p>La table &laquo; <b>account</b>&raquo; représentera donc un compte pour un client et rattaché à son type. Il contiendra le solde du compte à l'instant présent.</p>
            
                    <p>La table &laquo; <b>transaction</b>&raquo; regroupe l'ensemble des transactions réalisées. Pour toutes les transactions, il est primordial qu'elle provienne d'un compte et à destination d'un compte existant. On permet néanmoins que l'auteur puisse être à &laquo; null &raquo; dans le cas o&ugrave; c'est le syst&egrave;me qui réalise le transfert (commission, application des taux en début d'année, etc&hellip;). En dehors d'un cas particulier, l'auteur doit systématiquement être remplit.</p>
                    <p>La base de données sera disponible au format SQL dans le Starter (cf. &laquo; Travail à réaliser - Starter &raquo;). Les données par défaut seront à conserver et serviront de base de test (comptes avec les taux et seuil de découvert, utilisateurs, etc&hellip;). <span className="important">La structure de la base ne peut en aucun cas être modifiée</span>, vous êtes néanmoins libre de rajouter vos propres données pour tester tous les cas particuliers du projet.</p>
            
            
                    <h1 id="III"><span>Travail à réaliser</span></h1>
                    <h2 id="III-I"><span>I. Enterprise Java Bean</span></h2>
                    <p>La partie EJB correspond donc à l'aspect métier de notre application bancaire. L'ensemble des classes et méthodes permettant le fonctionnement de l'application devront &ecirc;tre implémentées dans cette partie (EJB). Elle sera entre autre constituée de :</p>
            
                    <ul>
                        <li>Session Beans (classe &amp; interfaces)</li>
                        <li>EJB Entities</li>
                        <li>Java Messaging Service</li>
                        <li>Persistance Manager</li>
                    </ul>
            
                    <p style={{textAlign: "center"}}><img alt="" src={architecture} /></p>
            
                    <p>La manipulation du front étant déjà réalisée par une équipe externe, vous devrez implémenter des Web services dans la partie Web afin de faire le lien entre le contr&ocirc;leur et le rendu visuel à savoir l'appel de vos &laquo; Sessions Beans &raquo; (EJB) et du formatage des données pour la construction des pages HTML.</p>
            
                    <h3>Déroulement étape par étape</h3>
            
                    <p>1. Construire vos &laquo; Sessions Beans &raquo; permettant de réaliser l'aspect métier de l'application. Pour ce faire, pour chaque classe, il faudra définir le périm&egrave;tre des actions de cette derni&egrave;re sur les données en base (contr&ocirc;le des données, interfaces accessibles, cycle de vie, attributs, etc&hellip;). Les données en base ne seront pas encore accessibles, il faudra donc simuler ces données avec des objets virtuels (MOCK) pour attester du bon fonctionnement de vos méthodes.</p>
            
                    <p>2. Elaboration et création de vos objets virtuels permettant de faire le lien entre vos données en base et le rendu visuel (et inversement). Ces classes devront &ecirc;tre construites en fonction de vos besoins, de la mani&egrave;re dont vous souhaitez organiser votre vue et surtout, de protéger vos EJB Entités. Nous verrons par la suite que ces objets qui font le pont entre nos données et notre vue apporteront une certaine souplesse dans le code.</p>
            
                    <p>3. Reliez vos &laquo; Sessions Beans &raquo; à votre vue. Utilisez vos objets virtuels pour organiser et afficher vos vues avec les informations souhaitées.</p>
            
                    <p>4. Construction des EJB Entités. Représentation de la base de données en Java (ORM = Object-Role Modeling), vous aurez pour t&acirc;che de réaliser les constructeurs, attributs, accesseurs, contraintes d'attributs, relations entre entités et enfin redéfinir les méthodes &laquo; toString &raquo;.</p>
            
                    <p>5. Reliez le &laquo; Persistance Manager &raquo; aux &laquo; Sessions Beans &raquo; et remplacez les données virtuelles par les vraies données de la base.</p>
            
                    <p>6. Réalisez les transactions en prenant en compte la gestion des exceptions ainsi que les rollbacks éventuels.</p>
            
                    <p>7. Modifiez votre &laquo; vue &raquo; pour prendre en compte ces levées d'exception et ainsi informer et orienter l'utilisateur en cas d'anomalie (découvert, erreur interne, etc&hellip;).</p>
            
                    <h2 id="III-II"><span>II. Front API</span></h2>
            
                    <p>Afin de faciliter vos tests (également pour la correction), une console en ligne représantant le front finalisé vous a été mis à disposition.</p>
                    <ul>
                        <li>Documentation</li>
                        <li>Tests</li>
                    </ul>
            
                    <h3>Déroulement étape par étape</h3>
                    <ol>
                        <li>Pour chaque demande du cahier des charges, construire l'ensemble des prototypes d'API en réfléchissant bien à la cohérence de vos requ&ecirc;tes.</li>
                        <li>Créez l'ensemble des payloads (input/output) vous permettant d'appeler l'ensemble des requ&ecirc;tes avec toutes les informations nécessaires.</li>
                        <li>Vos EJB étants maintenant disponible, vous n'avez qu'à les relier à vos web-services.</li>
                        <li>Vérifier que toutes les fonctionnalités du front fonctionnent.</li>
                    </ol>
            
                    <h3>Console en ligne</h3>
                    <p>Afin de faciliter vos tests (également pour la correction), une console en ligne représantant le front finalisé vous a été mis à disposition. Il vous suffit donc de vous connecter à la console et de tester l'ensemble de vos méthodes. L'équipe ayant réalisé le front n'avait pas encore accès à vos webservices, ils ont donc réalisé un MOCK pour chaque appel et vous ont mis à disposition dans la rubrique "Aide", l'ensemble des requêtes, méthodes et payloads à fournir pour pouvoir lier votre back au front.</p>
                    <p><span className="important">Important :</span> Il est possible que lors de votre premier appel, la console n'arrive pas à accéder à votre serveur. Pour des raisons de sécurité la console en ligne est sécurisée en HTTPS, les navigateurs ne permettant pas le mixed content (un site en HTTPS ne peut pas appeler un site en HTTP), vos webservices locaux sont donc en HTTPS. Localhost oblige, vous avez un certificat auto-signé qu'il faudra donc valider manuellement un première fois en vous rendant à l'adresse suivante : <a target="_blank" rel="noopener noreferrer" href="https://localhost:8001">https://localhost:8001</a></p>
                    <p style={{textAlign: "center"}}><img alt="" src={console} /></p>
                    <div className="console"><a href="accounts">Accéder à la console</a></div>
                    
                    <h3>Liste des Webservices à implémenter</h3>
                    <p>Pour tester votre application, vous n'aurez qu'à implémenter les WS demandés sur chaque page de la console. Ci-dessous, l'ensemble des WS à implémenter pour couvrir toutes les pages/demandes :</p>
                    <Help keys = {Object.keys(REQUESTS)} />
            
                    <h2 id="III-III"><span>III. Starter</span></h2>
                    <p>Pour vous épauler dans la réalisation de ce projet, une architecture de démarrage vous est fournie directement via un dép&ocirc;t Git publique. Ce starter contient une architecture prête et fonctionnelle du projet vous permettant de vous concentrer sur l'implémentation back-end du projet. Elle vous affranchit en autre de la construction du projet de base : build, installation de la base de données, configuration du serveur d'application, ...</p>
                    <p style={{textAlign: "center"}}><img alt="" src={github} /></p>
                    <div className="github"><a href="https://github.com/Mackile/ejbank.git" target="_blank" rel="noopener noreferrer">https://github.com/Mackile/ejbank.git</a></div>            
            
                    <h1 id="IV"><span>Modalités du rendu</span></h1>
            
                    <h2 id="IV-I"><span>I. Consigne</span></h2>
                    <p>Le projet est à faire par <span className="important">bin&ocirc;me</span>. Les personnes souhaitant réaliser le projet seuls sont autorisées à le faire dans la mesure o&ugrave; cela ne porte pas préjudice à un autre étudiant ne voulant pas &ecirc;tre dans cette situation. Notez que dans les deux cas, le bar&egrave;me de notation restera inchangé (il faut assumer).</p>
                    <p>Chacun de vos projets devront être hébergés sur un environnement de versionning en ligne (github, gitlab, etc). Le lien de votre dépôt vous sera demandé par binome et vous n'aurez donc plus qu'à vous assurer que votre projet est correctement synchronisé pour la date de rendu. Vous pouvez utiliser autant de branches, commit, tags que nécessaires, pour le rendu, <span className="important">seul le dernier commit (avant la dead-line) sur la branche &laquo; master&raquo; sera testé et fera office de rendu</span>. Pensez donc à bien &laquo; Push &raquo; vos modifications locales et à merger vos branches sur la master. Aucun build n'est demandé, vous devez uniquement versionner le code source.</p>
                    <p>Pour ceux qui ne souhaiteraient pas que leur dépôt soit public, voici comment sécuriser votre dépôt et me partager son accès :</p>
            
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="GitHub" key="1">
                            <h3>Sécuriser le projet</h3>
                            <p>Allez sur votre dépôt puis : Settings &gt; Options &gt; Change visibility (tout en bas) &gt; Make private</p>
                            <h3>Partager l'accès</h3>
                            <p>Allez sur votre dépôt puis : Settings &gt; Manage Access &gt; Invite a collaborator, il vous suffit ensuite d'ajouter <b>Mackile</b> pour que je sois autorisé à accéder au dépôt.</p>
                        </TabPane>
                        <TabPane tab="GitLab" key="2">
                            <h3>Sécuriser le projet</h3>
                            <p>Lors de la création de votre projet vous pouvez directement choisir &laquo; Private &raquo;.</p>
                            <h3>Partager l'accès</h3>
                            <p>Allez sur votre dépôt puis : Members &gt; Invite member, il vous suffit ensuite d'ajouter <b>Mackile</b> pour que je sois autorisé à accéder au dépôt.</p>
                        </TabPane>
                    </Tabs>
            
                    <h2 id="IV-II"><span>II. Notation</span></h2>
                    <p>Le bar&egrave;me sera réalisé lors de la correction des projets afin de valoriser les parties en fonction de l'avancement du groupe. Certains points du cahier des charges ont volontairement été laissés en suspens afin de voir votre faculté à appréhender votre vision du projet et la mani&egrave;re d'organiser votre code. Les points suivants seront en outre passés en revue :</p>
                    <ul>
                        <li>Organisation des classes (packages)</li>
                        <li>Périm&egrave;tre &amp; r&ocirc;le de chaque classe</li>
                        <li>Propreté du code (nom des méthodes, documentation, commentaires)</li>
                        <li>Bonne utilisation des états &laquo; Stateless &raquo; &amp; &laquo; Stateful &raquo;</li>
                        <li>Gestion des exceptions (et donc roll back éventuels)</li>
                        <li>Modularité du code</li>
                        <li>Construction des entités (contraintes des attributs et relation des tables)</li>
                        <li>Contr&ocirc;le des données (la vue n'est pas à l'abri de données erronées)</li>
                        <li>Gestion des transactions</li>
                        <li>Réalisation de toutes les fonctionnalités</li>
                        <li>Gestion des webservices</li>
                        <li>Construction des payloads</li>
                        <li>Analyse des branches & commits afin de voir l'évolution de votre projet au cours de la période donnée</li>
                    </ul>
            
                    <h2 id="IV-III"><span>III. Planning</span></h2>
                    <p>Nous disposerons de 3 séances de 4 heures. Chacune des séances portera sur un point en particulier sous forme de TP/cours afin d'approfondir chaque notion des connaissances requises pour réaliser l'ensemble des fonctionnalités de ce projet. <span className="important">Vous êtes néanmoins libre d'avancer à votre guise au cours des séances pour réaliser votre projet.</span></p>
                    <h3>Séance 1 :</h3>
                    <ul>
                        <li>Présentation</li>
                        <li>Lancement du projet</li>
                        <li>[BREAK]</li>
                        <li>Java Sessions Beans</li>
                        <li>Entity Manager x EJB Entities</li>
                    </ul>
                    <h3>Séance 2 :</h3>
                    <ul>
                        <li>Entity Manager - Queries</li>
                        <li>EJB Entities - Mapping</li>
                        <li>[BREAK]</li>
                        <li>EJB Entities - Relations</li>
                    </ul>
                    <h3>Séance 3 :</h3>
                    <ul>
                        <li>EJB Entities - Héritage</li>
                        <li>[BREAK]</li>
                        <li>Transaction</li>
                        <li>Web Services</li>
                    </ul>
                    <h3>Rendu :</h3>
                    <p>Le projet final sera à rendre au plus tard le <span className="important">Dimanche 5 Janvier 2025 à 14h00</span>.</p>
                    <p>Avant chaque séance, pour ceux qui le souhaitent, vous avez la possibilité de me solliciter par mail (<a href="mailto:mickael.boudignot@icloud.com">mickael.boudignot@icloud.com</a>) ou via le <b>Discord ESIPE-INFO</b> afin que j'inspecte votre avancement (via le dép&ocirc;t en ligne) et vous faire un retour sur votre travail à la séance suivante. L'application à réaliser n'est pas compliquée mais beaucoup de notions sont à voir, il est donc primordial que le planning de chaque séance soit respecté. Il est donc fortement conseillé d'avancer entre chaque séance pour arriver au cours suivant avec les notions vues précédemment acquises ou en attente de clarification.</p>
                    <p><b>Good luck&hellip;</b></p>
            
                    <h1 id="V"><span>Documentation</span></h1>
                    

                    <h2 id="V-I"><span>I. Pré-requis</span></h2>
                    <p>Vous êtes libre d'utiliser l'environnement qui vous convient le mieux</p>
                    <ul>
                        <li>JDK</li>
                        <li>Maven</li>
                        <li>Docker</li>
                        <li>Docker-compose</li>
                    </ul>
                    
                    <h2 id="V-II"><span>II. Installations</span></h2>
                    
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Environnement de l'école" key="1">
                            <p>Sur les environnements de l'école, vous aurez certainement besoin d'ajouter quelques packages afin de pouvoir compléter le projet.</p>

                            <p>Nous serrons systématiquement dans des salles réseaux où nous sommes admin des machines, pensez donc à nettoyer le système lors du démarrage :</p>
                            <p><b>rsync light (au moment du boot)</b></p>

                            <p>Se connecter avec le compte :</p>
                            <ul>
                                <li>Identifiant : root</li>
                                <li>Mot de passe : tpreseau</li>
                            </ul>

                            <p>Actualisation des dépôts :</p>
                            <pre>sudo apt-get update</pre>
                            <p>Il est possible que vous ayez à accepter les nouveaux dépôts, choisissez bien l'option : [o]</p>

                            <p>Installation des dépendances manquantes :</p>
                            <pre>sudo apt-get install git maven docker-ce docker-compose</pre>

                            <p>Veuillez configurer maven après son installation :</p>
                            <pre>export JAVA_HOME=/usr/lib/jvm/java-17-openjdk-amd64</pre>
                        </TabPane>
                        <TabPane tab="Ubuntu & Debian" key="2">
                            <p>Documentation pour installer :</p>
                            <ul>
                                <li>docker : <a href="https://docs.docker.com/engine/install/ubuntu/" target="_blank" rel="noopener noreferrer">https://docs.docker.com/engine/install/ubuntu/</a></li>
                                <li>docker-compose : <a href="https://docs.docker.com/compose/install/" target="_blank" rel="noopener noreferrer">https://docs.docker.com/compose/install/</a></li>
                            </ul>
                            <p>Pour pouvoir lancer les commandes liées à docker, vous devez ajouter votre utilisateur au groupe docker :</p>
                            <pre>sudo usermod -aG docker $USER</pre>
                        </TabPane>
                        <TabPane tab="Windows" key="3">
                        <p>Documentation pour installer :</p>
                            <ul>
                                <li>jdk : <a href="https://download.java.net/java/GA/jdk17.0.2/dfd4a8d0985749f896bed50d7138ee7f/8/GPL/openjdk-17.0.2_windows-x64_bin.zip" target="_blank" rel="noopener noreferrer">https://download.java.net/java/GA/jdk17.0.2/dfd4a8d0985749f896bed50d7138ee7f/8/GPL/openjdk-17.0.2_windows-x64_bin.zip</a></li>
                                <li>maven : <a href="https://maven.apache.org/download.cgi" target="_blank" rel="noopener noreferrer">https://maven.apache.org/download.cgi</a></li>
                                <li>docker : <a href="https://docs.docker.com/desktop/windows/install/" target="_blank" rel="noopener noreferrer">https://docs.docker.com/desktop/windows/install/</a></li>
                            </ul>
                        </TabPane>
                    </Tabs>
                    
                    <h2 id="V-III"><span>III. Récupération du projet Git</span></h2>
                    <p>Pour importer le projet, il vous suffit de partir de l'URI suivante du dép&ocirc;t :</p>
                    <pre>git clone <a href="https://github.com/Mackile/ejbank.git" target="_blank" rel="noopener noreferrer">https://github.com/Mackile/ejbank.git</a></pre>
                    <p>Le projet EJBank est un projet Maven qui package trois sous projets :</p>
                    <ul>
                        <li>EJBank-ear : pour packager notre EJB et WEB au sein d'un m&ecirc;me bundle</li>
                        <li>EJBank-ejb : la partie bean métier à développer</li>
                        <li>EJBank-web : la partie web qu'il faudra agrémenter de webservices</li>
                    </ul>
                    <p>Une fois que vous aurez récupéré le starter, il faudra le lier à votre propre dépôt pour ne pas perdre votre avancement et surtout pour le rendu du projet (<span className="important">ne pas oublier de me communiquer le lien de votre dépôt</span>) :</p>
                    <pre>git remote set-url origin <span style={{color: "#CC0000"}}>https://github.com/<b>VOTRE_COMPTE</b>/<b>NOM_DE_VOTRE_PROJET</b>.git</span></pre>
            
                    <h2 id="V-IV"><span>IV. Démarrer le projet</span></h2>
                    
                    <p>Pour compiler vos sources avec maven, placez-vous à la racine votre projet (celui qui contient les docker-compose) :</p>
                    <pre>mvn clean install</pre>
                    
                    <p>Pour lancer le projet, vous allez devoir démarrer l'architecture contenant le serveur d'application qui va exécuter votre application à l'aide de docker :</p>
                    <ul>
                        <li>Linux & Mac (une seule fois) :
                        <pre>sudo docker-compose up</pre></li>
                        <li>Windows (à chaque build) :
                        <pre>docker-compose -f docker-compose.windows.yml up --build</pre></li>
                    </ul>
                    <p>Votre build va générer une archive EAR qui sera envoyée au serveur d'application présent DANS le docker et ainsi déployer votre application. <b>A chaque fois que vous fairez une modification de projet, pour redéployer vos modifications, relancez uniquement la commande maven (sauf pour Windows où vous devrez également relancer la commande docker-compose)</b>.</p>
                    <p><span className="important">Note :</span> Au premier lancement de votre projet, la base de données sera déployée dans le container MySQL. Tant que vous ne supprimez pas vos containers, la base de données restera inchangée.</p>
                    <p>Le starter est livré avec un petit webservice et un EJB pour tester que toutes les interconnexions fonctionnent, vous pouvez tester avec la commande suivante :</p>
                    <pre>curl --insecure <a href="https://localhost:8001/test/ejb" target="_blank" rel="noopener noreferrer">https://localhost:8001/test/ejb</a></pre>
                    <p>Pour accéder à un conteneur docker (dans le cas où vous souhaiteriez accéder à la console et exécuter des commandes à l'intérieur de ce dernier) :</p>
                    <pre>sudo docker exec -ti <b>NOM_DU_CONTENEUR</b> bash</pre>
                    <h3>Conteneurs :</h3>
                    <ul>
                        <li>ejbank_app_1 : conteneur de votre application (port 8001 pour l'application & 8002 pour le serveur d'application)</li>
                        <li>ejbank_db_1 : conteneur de base de données</li>
                        <li>ejbank_phpmyadmin_1 : un conteneur vous permettant de manipuler votre base via phpMyAdmin (port 8003)
                            <ul>
                                <li>Accès à phpMyAdmin : <a href="http://localhost:8003/" target="_blank" rel="noopener noreferrer">http://localhost:8003</a></li>
                                <li>Identifiant : root</li>
                                <li>Mot de passe : root</li>
                            </ul>
                        </li>
                    </ul>
            
                    <p style={{textAlign: "center"}}><img alt="" src={docker} /></p>
            
                    <h2 id="V-V"><span>V. Importer le projet dans l'IDE</span></h2>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Eclipse" key="1">
                            <p>Page de téléchargement : <a target="_blank" rel="noreferrer" href="https://www.eclipse.org/downloads/packages/release/2023-09/r/eclipse-ide-enterprise-java-and-web-developers">https://www.eclipse.org/downloads/packages/release/2023-09/r/eclipse-ide-enterprise-java-and-web-developers</a></p>
                            
                            <h3>Installation pour Linux :</h3>
                            <pre>
                                <span>wget -O eclipse.tar.gz <a target="_blank" rel="noreferrer" href="http://mirror.ibcp.fr/pub/eclipse//technology/epp/downloads/release/2023-09/R/eclipse-jee-2023-09-R-linux-gtk-x86_64.tar.gz">http://mirror.ibcp.fr/pub/eclipse//technology/epp/downloads/release/2023-09/R/eclipse-jee-2023-09-R-linux-gtk-x86_64.tar.gz</a></span>
                                <br/><span>tar -xvf eclipse.tar.gz</span><br/>
                                <span>./eclipse/eclipse</span>
                            </pre>

                            <h3>Import du projet :</h3>
                            <p>Pour importer le projet dans Eclipse : File &gt; Import&hellip;</p>
                            <p>Puis dans la bo&icirc;te de dialogue : Maven &gt; Existing Maven Projects</p>
                            <p>Enfin il vous suffit de vous placer à la racine de votre projet et de valider l'ensemble des sous-projets à importer.</p>

                            <p><img alt="" src={eclipse1} width="45%" /><img alt="" src={eclipse2} width="45%" style={{float: "right"}} /></p>
                            <div style={{clear: "both"}}></div>
                        </TabPane>
                        <TabPane tab="Netbeans" key="2">
                            <p>Page de téléchargement : <a target="_blank" rel="noreferrer" href="https://netbeans.apache.org/download/nb120/nb120.html">https://netbeans.apache.org/download/nb120/nb120.html</a></p>
                            
                            <h3>Import du projet :</h3>
                            <p>Pour importer le projet dans Netbeans : File &gt; Open Project&hellip;</p>
                            <p style={{textAlign: "center"}}><img alt="" src={netbeans1} width="45%" /></p>
                            
                            <h3>Limiter les fonctionnalités du JDK :</h3>
                            <p>Il est possible que vous ayez un JDK suppérieur à celui utilisé par le serveur d'application. Pas besoin d'en installer un nouveau, il vous suffit de définir un JDK de destination à votre IDE pour que ce dernier bride certaines fonctionnalités présentes dans les versions supérieurs : Clique droit sur le projet &gt; Properties &gt; Sources &gt; Source/Binary Format et de choisir <b>JDK 17</b>.</p>
                        </TabPane>
                        <TabPane tab="IntelliJ" key="3">
                            <p>Page de téléchargement : <a target="_blank" rel="noreferrer" href="https://www.jetbrains.com/fr-fr/idea/download/">https://www.jetbrains.com/fr-fr/idea/download/</a></p>
                            
                            <h3>Limiter les fonctionnalités du JDK :</h3>
                            <p>Il est possible que vous ayez un JDK suppérieur à celui utilisé par le serveur d'application. Pas besoin d'en installer un nouveau, il vous suffit de définir un JDK de destination à votre IDE pour que ce dernier bride certaines fonctionnalités présentes dans les versions supérieurs : File &gt; Project Structure &gt; Onglet Project et choisissez votre &laquo; language level &raquo; pour le limiter au <b>JDK 17</b>.</p>
                        </TabPane>
                    </Tabs>
                    
                    <h1 id="VI"><span>Annexes</span></h1>
            
                    <h2 id="VI-I"><span>I. Schémas</span></h2>
            
                    <Carousel autoplay>
                        <div className="slide">
                            <img alt="" src={protocol} />
                        </div>
                        <div className="slide">
                            <img alt="" src={rmi} />
                        </div>
                        <div className="slide">
                            <img alt="" src={jndi} />
                        </div>
                        <div className="slide">
                            <img alt="" src={cluster} />
                        </div>
                    </Carousel>
            
                    <h2 id="VI-II"><span>II. Versions</span></h2>
            
                    <table className="two_columns">
                        <tbody>
                            <tr>
                                <td>
                                    <p>JEE API :</p>
                                </td>
                                <td>
                                    <p>7</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>JDK :</p>
                                </td>
                                <td>
                                    <p>17</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Serveur d'application :</p>
                                </td>
                                <td>
                                    <p>Payara 5</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Base de données :</p>
                                </td>
                                <td>
                                    <p>MySQL Community Edition 5.6</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Persistance Manager :</p>
                                </td>
                                <td>
                                    <p>EclispeLink 2.6</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>EJB :</p>
                                </td>
                                <td>
                                    <p>3</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Container :</p>
                                </td>
                                <td>
                                    <p>Docker + Compose</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Builder :</p>
                                </td>
                                <td>
                                    <p>Maven 3</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Gestionnaire de version :</p>
                                </td>
                                <td>
                                    <p>Git</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>API :</p>
                                </td>
                                <td>
                                    <p>Rest + Json</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Layout>
            );
export default Home;
